// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import kebabCase from 'lodash/kebabCase'
import startsWith from 'lodash/startsWith'
import isNull from 'lodash/isNull'

import find from 'lodash/find'
import isUndefined from 'lodash/isUndefined'
import classNames from 'classnames'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { compiler } from 'markdown-to-jsx'

import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import './style.less'

import Slider from '../slider'
import '../slider/style.less'

import StandardPageWrapper from '../standard-page-wrapper'
import '../standard-page-wrapper/style.less'

import Clipart from '../svg/clipart'

import ArticleSchema from '../schema/article-schema'

import indexImage from '../../images/banners/launch.jpg'

import strContains from '../../methods/str-contains'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const pageQuery = graphql`
  query PageQuery($position: Int, $positionP1: Int) {
    allResources(filter: { position: { gte: $position, lt: $positionP1 } }) {
      edges {
        node {
          title
          position
          routeSlug
          contentBlocks {
            type
            content
            subType
            resource {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            caption
            contentBlocks {
              type
              content
              subType
            }
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => {
  const {
    data: {
      allResources: { edges },
    },
    pageContext: { position },
  } = props

  const nodes = map(edges, 'node')
  const ordered = orderBy(nodes, ['position', 'asc'])
  const match = filter(nodes, ['position', position])

  const chapterTitle = match[0].title
  const chapterSlug = match[0].routeSlug

  const contentNode = find(ordered, (node) => node.contentBlocks.length > 0)
  const contentNodeContentBlocks = contentNode.contentBlocks
  const contentNodeAbstractBlockForChapter = find(
    contentNodeContentBlocks,
    (block) =>
      block.type === 'sub-text' ||
      block.type === 'text' ||
      block.type === 'block'
  )

  let chapterAbstract = ''
  if (isUndefined(contentNodeAbstractBlockForChapter) === false) {
    if (contentNodeAbstractBlockForChapter.type === 'sub-text') {
      chapterAbstract = contentNodeAbstractBlockForChapter.content
    }
    if (contentNodeAbstractBlockForChapter.type === 'text') {
      chapterAbstract = contentNodeAbstractBlockForChapter.content
    }
    if (contentNodeAbstractBlockForChapter.type === 'block') {
      chapterAbstract =
        contentNodeAbstractBlockForChapter.contentBlocks[0].content
    }
  }

  const contentNodeImageBlockForChapter = find(
    contentNodeContentBlocks,
    (block) => block.type === 'carousel' || block.type === 'image'
  )

  let chapterImage = indexImage
  if (isUndefined(contentNodeImageBlockForChapter) === false) {
    if (contentNodeImageBlockForChapter.type === 'carousel') {
      if (contentNodeImageBlockForChapter.resources.length > 0) {
        chapterImage =
          contentNodeImageBlockForChapter.resources[0].resource.childImageSharp
            .gatsbyImageData.images.fallback.src
      }
    } else if (contentNodeImageBlockForChapter.type === 'image') {
      chapterImage =
        contentNodeImageBlockForChapter.resource.childImageSharp.gatsbyImageData
          .images.fallback.src
    }
  }

  const pageSchema = {
    title: chapterTitle,
    slug: chapterSlug,
    abstract: chapterAbstract,
    breadcrumbs: [
      { title: 'Cover Page', slug: '' },
      { title: chapterTitle, slug: chapterSlug },
    ],
    cover: chapterImage,
  }

  return (
    <StandardPageWrapper
      className="book-page"
      pageSchema={pageSchema}
      {...props}
    >
      {map(ordered, ({ title, routeSlug, contentBlocks }, index) => {
        let sectionTitle = chapterTitle
        let sectionSlug = chapterSlug
        let sectionAbstract = chapterAbstract
        let sectionImage = chapterImage

        if (index !== 0) {
          sectionTitle = `${chapterTitle}; Section ${index}. ${title}`
          sectionSlug = `${chapterSlug}#${routeSlug}`

          const contentNodeAbstractBlockForSection = find(
            contentBlocks,
            (block) =>
              block.type === 'sub-text' ||
              block.type === 'text' ||
              block.type === 'block'
          )
          if (contentNodeAbstractBlockForSection.type === 'sub-text') {
            sectionAbstract = contentNodeAbstractBlockForSection.content
          }
          if (contentNodeAbstractBlockForSection.type === 'text') {
            sectionAbstract = contentNodeAbstractBlockForSection.content
          }
          if (contentNodeAbstractBlockForSection.type === 'block') {
            sectionAbstract =
              contentNodeAbstractBlockForSection.contentBlocks[0].content
          }

          const contentNodeImageBlockForSection = find(
            contentBlocks,
            (block) => block.type === 'carousel' || block.type === 'image'
          )

          if (isUndefined(contentNodeImageBlockForSection) === false) {
            if (contentNodeImageBlockForSection.type === 'carousel') {
              if (contentNodeImageBlockForSection.resources.length > 0) {
                sectionImage =
                  contentNodeImageBlockForSection.resources[0].resource
                    .childImageSharp.gatsbyImageData.images.fallback.src
              }
            } else if (contentNodeImageBlockForSection.type === 'image') {
              sectionImage =
                contentNodeImageBlockForSection.resource.childImageSharp
                  .gatsbyImageData.images.fallback.src
            }
          }
        }

        const articleSchemaData = {
          name: sectionTitle,
          slug: sectionSlug,
          articleBody: sectionAbstract,
          breadcrumbs: [
            { title: 'Cover Page', slug: '' },
            { title: chapterTitle, slug: chapterSlug },
            { title: sectionTitle, slug: sectionSlug },
          ],
          cover: sectionImage,
        }

        return (
          <Fragment>
            <ArticleSchema data={articleSchemaData} />
            {index === 0 && (
              <h1 id={`${kebabCase(title)}`}>
                <big>{title}</big>
              </h1>
            )}
            {index !== 0 && (
              <h2 id={`${kebabCase(title)}`}>
                <i>{title}</i>
              </h2>
            )}
            {map(contentBlocks, (block) => {
              const {
                type,
                subType = null,
                content,
                resources,
                resource,
                contentBlocks: childContentBlocks,
                caption = null,
              } = block
              let returnThis = <Fragment />

              if (type === 'clipart') {
                returnThis = (
                  <div className="clipart as-paragraph">
                    <Clipart />
                  </div>
                )
              }

              if (type === 'block') {
                returnThis = (
                  <div className={`block as-paragraph ${subType}`}>
                    {map(childContentBlocks, (childBlock) => {
                      const {
                        type: childType,
                        subType: childSubType = null,
                        content: childContent,
                      } = childBlock

                      let returnThat = <Fragment />

                      if (childType === 'sub-text') {
                        returnThat = (
                          <p style={{}}>
                            <i>
                              <strong style={{ fontWeight: 600 }}>
                                {compiler(childContent, { wrapper: null })}
                              </strong>
                            </i>
                          </p>
                        )
                      }

                      if (childType === 'text') {
                        returnThat = (
                          <p className={childSubType}>
                            {compiler(childContent, { wrapper: null })}
                          </p>
                        )
                      }

                      if (childType === 'strong') {
                        returnThat = <h2>{childContent}</h2>
                      }

                      return returnThat
                    })}
                  </div>
                )
              }

              if (type === 'carousel') {
                returnThis = <Slider resources={resources} />
              }

              if (type === 'text') {
                returnThis = (
                  <p className={subType}>
                    {compiler(content, { wrapper: null })}
                  </p>
                )
              }

              if (type === 'section') {
                returnThis = <h2>{content}</h2>
              }

              if (type === 'image') {
                const image = getImage(resource)

                if (isNull(caption) === false) {
                  returnThis = (
                    <div className={classNames('with-caption', subType)}>
                      <GatsbyImage image={image} />
                      <p className="caption">
                        {compiler(caption, { wrapper: null })}
                      </p>
                    </div>
                  )
                } else {
                  returnThis = (
                    <GatsbyImage
                      className={classNames('as-paragraph', subType)}
                      image={image}
                    />
                  )
                }
              }

              return returnThis
            })}
          </Fragment>
        )
      })}
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
