import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      width={902}
      height={148}
      viewBox="0 0 902 148"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M1 1h900v146H1z" />
        <path
          d="M9 47.578c49.066 2.703 97.691 2.703 145.875 0C227.152 43.524 305.091 9 325.805 9 346.52 9 435.4 48.692 516.144 53.02c80.742 4.327 127.773-5.151 187.722 0 59.949 5.15 54.512 5.15 92.229 20.48 25.145 10.22 57.171 28.906 96.08 56.059-44.037-16.457-79.122-27.493-105.254-33.108-39.199-8.422-53.36-8.422-83.055-7.352-29.696 1.07-151.188 18.226-224.147 32.06C406.758 134.993 364.421 139.6 261.122 138c-103.3-1.6-147.827-7.18-192.53-23.212C38.789 104.099 18.925 81.696 9 47.578z"
          fill="#000"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
